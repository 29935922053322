$(document).ready(function () {
  let p = $("body").attr("p");
  let organizerId = $("body").attr("organizer_id");

  if (p == "organizer_giftcards_customers") {
    let order_by = "O.reg_date";
    let order_by_direction = "desc";
    let page = 1;
    let search_string = "";
    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      search_string = $("#search_string").val();
      order_by = $(this).attr("id");
      order_by_direction = $(this).attr("data-order-direction");

      getGiftcardCustomerList(
        page,
        search_string,
        order_by,
        order_by_direction,
        organizerId
      );
    });

    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();

      page = parseInt($(this).attr("href").substring(6));
      search_string = $("#search_string").val();

      getGiftcardCustomerList(
        page,
        search_string,
        order_by,
        order_by_direction,
        organizerId
      );
      console.log(page);
    });
  }

  function getGiftcardCustomerList(
    page = 1,
    search_string = "",
    order_by = null,
    order_by_direction = "asc",
    organizer_id
  ) {
    let data = {
      page,
      search_string,
      order_by,
      order_by_direction,
      organizer_id,
    };

    $.ajax({
      type: "POST",
      url: "/ajax/organizer_giftcards_customers/search",
      data: data,
    })
      .done(function (data) {
        if (data.html !== undefined && data.html !== null) {
          $("#giftcard-customer-list").empty();
          $("#giftcard-customer-list").html(data.html);
        }

        $("html,body").scrollTop(0);
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });
  }
});
