$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "event" && a === "show") {
    let order_gift_card_validation_ajax_lock = false;

    $(document).on("click", "#gift-card-code-button", function () {
      validateGiftCard();
    });

    function validateGiftCard() {
      let organizerId = $("body").attr("organizer_id");
      let giftCardCode = $("#gift_card_code").val();

      if (!order_gift_card_validation_ajax_lock) {
        order_gift_card_validation_ajax_lock = true;

        $.ajax({
          type: "POST",
          url: "/ajax/order/validate_gift_card",
          data: {
            organizer_id: organizerId,
            gift_card_code: giftCardCode,
          },
        })
          .done(function (data) {
            if (
              data.gift_card_discount !== undefined &&
              data.gift_card_discount !== null &&
              data.total !== undefined &&
              data.total !== null
            ) {
              let orderSummaryTableBody = document.getElementById(
                "table-order-summary"
              );
              let rows = orderSummaryTableBody.rows;

              let colsSum = rows[rows.length - 2].cells;
              colsSum[
                colsSum.length - 1
              ].innerHTML = `<strong>kr ${data.total}</strong>`;

              $("#gift-card-td").remove();
              rows[rows.length - 2].insertAdjacentHTML(
                "beforebegin",
                "<tr id='gift-card-td'><td colspan='4' >Rabatt (gavekort: <span id='gift_card_code_validated'>" +
                  giftCardCode +
                  "</span>)</td><td class='text-right'>- kr " +
                  data.gift_card_discount +
                  "</td></tr>"
              );

              $(".alert-danger").remove();
            }

            if (
              data.success_message !== undefined &&
              data.success_message !== null
            ) {
              prependAlert(
                "#gift-card-code-form",
                data.success_message,
                "success",
                null,
                "#order_register_form"
              );
            }

            order_gift_card_validation_ajax_lock = false;
          })
          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }

            if (
              data.responseJSON.error_message !== undefined &&
              data.responseJSON.error_message !== null
            ) {
              prependAlert(
                "#gift-card-code-form",
                data.responseJSON.error_message,
                "danger",
                null,
                "#order_register_form"
              );
            }

            order_gift_card_validation_ajax_lock = false;
          })
          .always(function () {
            order_gift_card_validation_ajax_lock = false;
          });
      }
    }

    $(document).on("click", "#gift-card-code-form-button", function () {
      if ($("#gift-card-code-form").hasClass("d-none")) {
        $("#gift-card-code-form").removeClass("d-none");
      } else {
        $("#gift_card_code").val(null);
        $(".alert-danger").remove();
        $("#gift-card-code-form").addClass("d-none");
      }
      return false;
    });
  }
});
