$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer_event_visitor_sales_day_of_week') {

            let eventId = $("body").attr("event_id");
            let startDate = $('#start-date').val();
            let endDate = $('#end-date').val();
            let period = 'week';
            let orderOrigin = $("#order_origin").children("option:selected").val();
            let startDateId = $('#start-date');
            let endDateId = $('#end-date');
            let periodWeekId = $('#period-week');
            let eventStatistic = $('.event-statistic');
            let eventSalesHref = $('#event-sales').attr('href');
            let timeslotSelect = $('#event-visitor-sales-day-of-week-filters-timeslot #timeslot_id');
            if (periodWeekId.prop("checked") === false) {
                periodWeekId.prop("checked", true);
            }

            if ($("body").attr("start_date") !== undefined) {
                startDate = $("body").attr("start_date");
                startDateId.val(startDate);
            }
            if ($("body").attr("end_date") !== undefined) {
                endDate = $("body").attr("end_date");
                endDateId.val(endDate);
            }
            if ($("body").attr("period") !== undefined) {
                period = $("body").attr("period");
                $("input[name=period][value=" + period + "]").prop('checked', true);
            }
            if ($("body").attr("order_origin") !== undefined) {
                orderOrigin = $("body").attr("order_origin");
                $("#order_origin").val(orderOrigin);
            }

            let params = {
                event_id: eventId,
                start: startDate,
                end: endDate,
                period: period,
                order_origin: orderOrigin
            };

            addEventSalesDateAndPeriodToLink(eventSalesHref, startDate, endDate, period, orderOrigin);
            ajaxSubmitParamsAndGetEventSalesInformation(params);

            eventStatistic.change(function () {
                startDate = $('#start-date').val();
                endDate = $('#end-date').val();
                period = $('input[name=period]:checked').val();
                orderOrigin = $("#order_origin").children("option:selected").val();
                addEventSalesDateAndPeriodToLink(eventSalesHref, startDate, endDate, period, orderOrigin);
                $("#event-visitor-sales-day-of-week").submit();
            });

            $('select.select2-select').on('change', function() {
                $("#event-visitor-sales-day-of-week").submit();
            });

            $('[data-toggle="tooltip"]').tooltip();

            $(document).on("submit", "#event-visitor-sales-day-of-week", function (e) {
                e.preventDefault();
                ajaxSubmitParamsAndGetEventSalesInformation($(this).serialize());
            });


            function ajaxSubmitParamsAndGetEventSalesInformation(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_visitor_sales_day_of_week/sales",
                    data: params
                })
                    .done(function (data) {
                        barChart('visitors-dow-tickets', data.date, data.ticket);
                        barChart('visitors-dow-revenue', data.date, data.revenue);

                        if(timeslotSelect.length > 0 && data.timeslots !== undefined){
                            timeslotSelect.html(data.timeslots);
                            timeslotSelect.select2({
                                theme: 'bootstrap4',
                                placeholder: ""
                            });
                        }
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            }

            function barChart(id, categories, series) {
                Highcharts.chart(id, {
                    chart: {type: 'column', styledMode: true},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: categories,
                        title: {text: $("#" + id).attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#" + id).attr("data-y-axis-text")}
                    },
                    credits: {enabled: false},
                    series: series
                });

            }

            function addEventSalesDateAndPeriodToLink(eventSalesHref, startDate, endDate, period, orderOrigin) {
                $('#event-sales').attr("href", eventSalesHref + "/" + startDate + "/" + endDate + "/" + period + "/" + orderOrigin);
            }

            $('select.select2-select').select2({
                theme: 'bootstrap4',
                placeholder: ""
            });
        }
    }
);
