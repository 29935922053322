$(document).ready(function () {
  let p = $("body").attr("p");
  let organizerId = $("body").attr("organizer_id");
  if (p === "organizer_giftcards_settings") {
    $(document).on("submit", "#gift_card_setting_form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);

      $.ajax({
        type: "POST",
        url: "/ajax/organizer_giftcards_settings/update",
        data: formData,
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data) {
            prependAlert(
              "#gift-card-setting-message",
              data.message,
              "success",
              null,
              "#gift-card-setting-message"
            );
            $("#gift-card-setting-form-container").hide();
          }
          setTimeout(function () {
            document.location.href =
              "/organizer/" + organizerId + "/giftcards/sales";
          }, 3000);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
