$(document).ready(function () {
  let p = $("body").attr("p");
  let organizerId = $("body").attr("organizer_id");
  if (p === "organizer_giftcards_sales") {
    let startDate = $("#start-date").val();
    let endDate = $("#end-date").val();
    let period = "week";
    let orderOrigin = -1;

    if ($("#period-week").prop("checked") === false) {
      $("#period-week").prop("checked", true);
    }

    $(document).on("change", ".giftcard-statistic", function (e) {
      e.preventDefault();
      startDate = $("#start-date").val();
      endDate = $("#end-date").val();
      period = $('input[name="period"]:checked').val();
      orderOrigin = $("#order_origin").val();
      getGiftcardStatisticData(startDate, endDate, period, orderOrigin);
    });

    //start-end date filter
    $(document).on("change", "#start-date", function (e) {
      e.preventDefault();
      startDate = $(this).val();
      endDate = $("#end-date").val();
      period = $('input[name="period"]:checked').val();
      orderOrigin = $("#order_origin").val();
      if (checkDateValidation(startDate, endDate)) {
        getGiftcardStatisticData(startDate, endDate, period, orderOrigin);
      } else {
        $(this).focus();
        return;
      }
    });

    $(document).on("change", "#end-date", function (e) {
      e.preventDefault();
      endDate = $(this).val();
      startDate = $("#start-date").val();
      period = $('input[name="period"]:checked').val();
      orderOrigin = $("#order_origin").val();
      if (checkDateValidation(startDate, endDate)) {
        getGiftcardStatisticData(startDate, endDate, period, orderOrigin);
      } else {
        $(this).focus();
        return;
      }
    });

    function checkDateValidation(filterStartDate, endDate) {
      if (!filterStartDate || !endDate) {
        alert("Både startdato og sluttdato er obligatorisk.");
        return false;
      }

      if (new Date(endDate) < new Date(startDate)) {
        alert("Sluttdato kan ikke være før startdato.");
        return false;
      }

      return true;
    }

    function giftCardLineGraph(primalData) {
      for (let key in primalData.ticket) {
        if (primalData.ticket.hasOwnProperty(key)) {
          let cumulativeData = [0];
          primalData.ticket[key].data.forEach(function (elementToAdd, index) {
            let newElement = cumulativeData[index] + elementToAdd;
            cumulativeData.push(newElement);
          });
          cumulativeData.shift();
          primalData.ticket[key].data = cumulativeData;
        }
      }

      Highcharts.chart("giftcard-line-graph", {
        chart: { type: "area", styledMode: true },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                "separator",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
                "separator",
                "downloadCSV",
                "downloadXLS",
              ],
            },
          },
        },
        title: false,
        xAxis: {
          categories: primalData.date,
          title: { text: $("#giftcard-line-graph").attr("data-x-axis-text") },
        },
        yAxis: {
          title: {
            text: $("#giftcard-line-graph").attr(
              "data-y-axis-accumulated-text"
            ),
          },
        },
        credits: { enabled: false },
        plotOptions: {
          area: {
            stacking: "normal",
            lineColor: "#666666",
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: "#666666",
            },
          },
        },
        series: primalData.ticket,
      });
    }

    function giftCardBarGraph(primalData) {
      Highcharts.chart("giftcard-bar-graph", {
        chart: { type: "column", styledMode: true },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                "separator",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
                "separator",
                "downloadCSV",
                "downloadXLS",
              ],
            },
          },
        },

        title: false,
        xAxis: {
          categories: primalData.date,
          title: { text: $("#giftcard-bar-graph").attr("data-x-axis-text") },
        },
        yAxis: {
          title: { text: $("#giftcard-bar-graph").attr("data-y-axis-text") },
        },
        credits: { enabled: false },
        series: primalData.ticket,
        plotOptions: {
          area: {
            stacking: "normal",
            lineColor: "#666666",
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: "#666666",
            },
          },
        },
      });
    }

    function getGiftcardStatisticData(startDate, endDate, period, orderOrigin) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_giftcards_sales/get_sold_tickets",
        data: { startDate, endDate, period, orderOrigin },
      })
        .done(function (data) {
          let totalSoldTicket = 0;
          let totalRevenue = 0;

          if (data.ticket) {
            const countArray = data.ticket[0].data;
            const revenueArray = data.ticket[0].revenue;

            countArray.forEach((item) => {
              totalSoldTicket += parseInt(item, 10);
            });
            revenueArray.forEach((item) => {
              totalRevenue += parseFloat(item);
            });
          }

          $("#key-figures-number-of-sold-tickets").text(totalSoldTicket);
          $("#key-figures-giftcard-revenue").text(
            formatPriceNumber(parseFloat(totalRevenue))
          );

          let lineChartData = jQuery.extend(true, {}, data);
          giftCardLineGraph(lineChartData);
          giftCardBarGraph(data);
          disablePeriodHour();
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function disablePeriodHour() {
      if (
        startDate.length !== 0 &&
        endDate.length !== 0 &&
        Date.parse(endDate) - Date.parse(startDate) < 172800 * 1000
      ) {
        $("#period-hour").prop("disabled", false);
      } else {
        if ($("#period-hour").prop("checked") === true) {
          $("#period-hour").prop("checked", true);
        }
        $("#period-hour").prop("disabled", true);
      }
    }

    function formatPriceNumber(number) {
      if (Number.isInteger(number)) {
        return number.toLocaleString("no-NO") + ",00";
      } else {
        return number.toLocaleString("no-NO", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    getGiftcardStatisticData(startDate, endDate, period, orderOrigin);
  }
});
