$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p === 'organizer_event_sales_tickets') {
            let eventId = $("body").attr("event_id");
            let startDate = $('#start-date').val();
            let endDate = $('#end-date').val();
            let period = 'day';
            let orderOrigin = $("#order_origin").children("option:selected").val();
            let numberOfSoldTicketsId = $('#key-figures-number-of-sold-tickets');
            let eventRevenueId = $('#key-figures-event-revenue');
            let startDateId = $('#start-date');
            let periodHourId = $('#period-hour');
            let periodWeekId = $('#period-week');
            let endDateId = $('#end-date');
            let periodDayId = $('#period-day');
            let eventStatistic = $('.event-statistic');
            let eventTicketCategorySalesHref = $('#event-ticket-category-sales').attr('href');
            let eventTabs = $('#event-tabs');
            let timeslotsUrlPart = '';
            let timeslotSelect = $('#event-visitor-sales-day-of-week-filters-timeslot #timeslot_id');
            if (eventTabs.length > 0 && eventTabs.data('timeslots') == 1) {
                timeslotsUrlPart = '/timeslots';
            }

            if (periodDayId.prop("checked") === false) {
                periodDayId.prop("checked", true);
            }

            if ($("body").attr("start_date") !== undefined) {
                startDate = $("body").attr("start_date");
                startDateId.val(startDate);
            }
            if ($("body").attr("end_date") !== undefined) {
                endDate = $("body").attr("end_date");
                endDateId.val(endDate);
            }
            if ($("body").attr("period") !== undefined) {
                period = $("body").attr("period");
                $("input[name=period][value=" + period + "]").prop('checked', true);
            }
            if ($("body").attr("order_origin") !== undefined) {
                orderOrigin = $("body").attr("order_origin");
                $("#order_origin").val(orderOrigin);
            }

            let params = {
                event_id: eventId,
                start: startDate,
                end: endDate,
                period: period,
                order_origin: orderOrigin
            };

            addEventTicketCategorySalesDateAndPeriodToLink(eventTicketCategorySalesHref, startDate, endDate, period, orderOrigin);
            ajaxSubmitParamsAndGetEventSalesInformation(params);

            startDateId.change(function () {
                disablePeriodHour();
            });

            endDateId.change(function () {
                disablePeriodHour();
            });

            eventStatistic.change(function () {
                startDate = $('#start-date').val();
                endDate = $('#end-date').val();
                period = $('input[name=period]:checked').val();
                orderOrigin = $("#order_origin").children("option:selected").val();
                addEventTicketCategorySalesDateAndPeriodToLink(eventTicketCategorySalesHref, startDate, endDate, period, orderOrigin);
                $("#event_statistic_form").submit();
            });

            $('select.select2-select').on('change', function() {
                $("#event_statistic_form").submit();
            });

            $('[data-toggle="tooltip"]').tooltip();

            $(document).on("submit", "#event_statistic_form", function (e) {
                e.preventDefault();
                ajaxSubmitParamsAndGetEventSalesInformation($(this).serialize());
            });

            function ajaxSubmitParamsAndGetEventSalesInformation(params) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_sales_tickets/sales",
                    data: params
                })
                    .done(function (data) {
                        let numberOfSoldTickets = data.number_of_sold_tickets;
                        let eventRevenue = data.event_revenue;
                        numberOfSoldTicketsId.text(numberOfSoldTickets);
                        eventRevenueId.text("kr " + parseFloat(eventRevenue).format(2, 3, ' ', ','));
                        lineChart(data);
                        barChart(data);

                        if(timeslotSelect.length > 0 && data.timeslots !== undefined){
                            timeslotSelect.html(data.timeslots);
                            timeslotSelect.select2({
                                theme: 'bootstrap4',
                                placeholder: ""
                            });
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function lineChart(primalData) {

                let cumulativeData = [0];
                primalData.ticket.forEach(function (elementToAdd, index) {
                    let newElement;
                    if (typeof elementToAdd === 'object') {
                        newElement = cumulativeData[index] + elementToAdd.y;
                    } else {
                        newElement = cumulativeData[index] + elementToAdd;
                    }
                    cumulativeData.push(newElement);
                });
                cumulativeData.shift();

                Highcharts.chart('event-line-graph', {
                    chart: {type: 'area', styledMode: true},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        labels: {
                            formatter: function () {
                                if (typeof this.value === 'object' && this.value !== null && this.value.x !== undefined && this.value.event_subevent_id !== undefined) {
                                    return '<a href="/organizer/event/' + eventId + '/subevent/' + this.value.event_subevent_id + '/sales' + timeslotsUrlPart + '" class="graph-label-link">' + this.value.x + '</a>';
                                } else {
                                    return this.value;
                                }
                            },
                            useHTML: true
                        },
                        title: {text: $("#event-line-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-line-graph").attr("data-y-axis-accumulated-text")}
                    },
                    plotOptions: {
                        column: {
                            point: {
                                events: {
                                    click: function () {
                                        if (this.event_subevent_id !== undefined) {
                                            document.location.href = '/organizer/event/' + eventId + '/subevent/' + this.event_subevent_id + '/sales' + timeslotsUrlPart;
                                        }
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            let x = this.series.name;
                            if (typeof this.x === 'object' && this.x.x !== undefined) {
                                x = this.x.x;
                            }
                            return '<span class="graph-tooltip-x">' + x + '</span><br><span style="color:' + this.series.chart.options.colors[this.point.colorIndex] + ';" class="graph-tooltip-y-dot">● </span><span class="graph-tooltip-y"><b>' + this.point.series.yAxis.axisTitle.textStr + ': ' + this.y + '</b></span>';
                        }
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: cumulativeData,
                        name: $("#event-line-graph").attr("data-y-axis-accumulated-text")
                    }]
                });
            }

            function barChart(primalData) {

                Highcharts.chart('event-bar-graph', {
                    chart: {type: 'column', styledMode: true},
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'viewFullscreen',
                                    'printChart',
                                    'separator',
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'separator',
                                    'downloadCSV',
                                    'downloadXLS'
                                ]
                            }
                        },
                        chartOptions: {
                            xAxis: {
                                labels: {
                                    formatter: function () {
                                        console.log('1')
                                        if (typeof this.value === 'object' && this.value !== null && this.value.x !== undefined && this.value.event_subevent_id !== undefined) {
                                            return this.value.x
                                        } else {
                                            return this.value;
                                        }
                                    },
                                    useHTML: true
                                }
                            },
                            yAxis: {
                                labels: {
                                    formatter: function () {
                                        console.log('2')
                                        if (typeof this.value === 'object' && this.value !== null && this.value.x !== undefined && this.value.event_subevent_id !== undefined) {
                                            return this.value.x
                                        } else {
                                            return this.value;
                                        }
                                    },
                                    useHTML: true
                                }
                            }
                        }
                    },
                    title: false,
                    xAxis: {
                        categories: primalData.date,
                        labels: {
                            formatter: function () {
                                if (typeof this.value === 'object' && this.value !== null && this.value.x !== undefined && this.value.event_subevent_id !== undefined) {
                                    return '<a href="/organizer/event/' + eventId + '/subevent/' + this.value.event_subevent_id + '/sales' + timeslotsUrlPart + '" class="graph-label-link">' + this.value.x + '</a>';
                                } else {
                                    return this.value;
                                }
                            },
                            useHTML: true
                        },
                        title: {text: $("#event-bar-graph").attr("data-x-axis-text")}
                    },
                    yAxis: {
                        title: {text: $("#event-bar-graph").attr("data-y-axis-text")}
                    },
                    plotOptions: {
                        column: {
                            point: {
                                events: {
                                    click: function () {
                                        if (this.event_subevent_id !== undefined) {
                                            document.location.href = '/organizer/event/' + eventId + '/subevent/' + this.event_subevent_id + '/sales' + timeslotsUrlPart;
                                        }
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            let x = this.series.name;
                            if (typeof this.x === 'object' && this.x.x !== undefined) {
                                x = this.x.x;
                            }

                            return '<span class="graph-tooltip-x">' + x + '</span><br><span style="color:' + this.series.chart.options.colors[this.point.colorIndex] + ';" class="graph-tooltip-y-dot">● </span><span class="graph-tooltip-y"><b>' + this.point.series.yAxis.axisTitle.textStr + ': ' + this.y + '</b></span>';
                        }
                    },
                    credits: {enabled: false},
                    series: [{
                        showInLegend: false,
                        data: primalData.ticket,
                        name: $("#event-bar-graph").attr("data-y-axis-text")
                    }]
                });
            }

            function disablePeriodHour() {
                if (startDateId.val().length !== 0 &&
                    endDateId.val().length !== 0 &&
                    (Date.parse(endDateId.val()) - Date.parse(startDateId.val())) < (172800 * 1000)) {
                    $(periodHourId).prop("disabled", false);
                } else {
                    if (periodHourId.prop("checked") === true) {
                        periodWeekId.prop("checked", true);
                    }
                    $(periodHourId).prop("disabled", true);
                }
            }

            function addEventTicketCategorySalesDateAndPeriodToLink(eventTicketCategorySalesHref, startDate, endDate, period, orderOrigin) {
                $('#event-ticket-category-sales').attr("href", eventTicketCategorySalesHref + "/" + startDate + "/" + endDate + "/" + period + "/" + orderOrigin);
            }

            $(document).on("click", "#organizer_event_sales_tickets_excel_btn", function (e) {
                const currentUrl = $('.nav-link.active').attr('href');
                document.location.href = currentUrl + '?file=csv&start=' + $('#start-date').val() + '&end=' + $('#end-date').val() + '&order_origin=' + $("#order_origin").children("option:selected").val();
            });

            $('select.select2-select').select2({
                theme: 'bootstrap4',
                placeholder: ""
            });
        }

    }
);